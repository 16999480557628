$width-boundary: 640px;
@mixin mobile {
    @media screen and (max-width: $width-boundary) {
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: $width-boundary+1) {
        @content;
    }
}

body {
    word-break: keep-all;
}

.title {
    @include desktop {
        font-size: 22px;
        &.welcome-box {
            font-size: 22px !important;
            margin-left: auto;
            margin-right: auto;
        }
    }
    @include mobile {
        font-size: 22px;
    }
}

.value {
    font-size: 33px;
}

.header {
    font-size: 15px;
}

.description {
    font-size: 14px;
    line-height: 1.71;
}

.placeholder {
    font-size: 14px;
}

.small-title {
    font-size: 12px;
}

.error {
    font-size: 10px;
}

.info {
    font-size: 10px;
}

.text {
    &-bold {
        font-weight: bold !important;
    }
    &-light {
        font-weight: 300 !important;
    }
    &-sm-spacing {
        letter-spacing: 0.1px;
    }
    &-center {
        text-align: center;
    }
}

.button {
    font-size: 14px;
    vertical-align: middle;
    text-align: center;
    &-gradient {
        letter-spacing: 0.08px !important;
    }
    &-login {
        letter-spacing: 0.08px !important;
    }
    &.hot .button-text {
        text-shadow: #fff368 1px 0 10px;
    }
}

.issueBox {
    .issue {
        &.hot {
            text-shadow: #fff368 1px 0 10px;
        }
    }
}

.filter-text {
    &.active {
        font-weight: 700 !important;
        text-shadow: #fff368 1px 0 20px;
    }
}

.freeform-input {
    font-size: 16px !important;
}

.result-box .opinion-box {
    .opinion {
        line-height: 1.57;
        font-size: 18px;
        font-weight: 400;
    }
    .demographic {
        .text {
            font-size: 15px;
        }
    }
    .like {
        font-size: 16px;
        line-height: 1.43;
    }
}

.underline {
    text-decoration: underline;
}

.issue {
    font-size: 1rem;
    line-height: 1.33;
}

.issue-title {
    font-size: 22px;
}

.filter-column {
    font-size: 12px;
    line-height: 1.33;
}

a {
    text-decoration: none;
}

.dropdown-button {
    font-size: 14px;
}

.creators {
    .creators-text {
        font-weight: bold;
    }
    .person {
        font-size: 14px;
    }
}

.dashed {
    text-decoration-line: underline;
    text-decoration-style: dashed;
    cursor: pointer;
}
